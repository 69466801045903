import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Scroll from 'react-scroll';

// Components
import Hero from '../content/Hero';
import LogoList from '../content/LogoList';
import Meta from '../../shared/Meta';
import ServicesForm from '../ServicesForm';
import Triple from '../content/Triple';
import CtaImage from '../content/CtaImage';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';
import servicesActions from '../../../actions/servicesActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class OfficeParks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      submitting: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  onFormSubmit(data) {
    this.setState({ submitting: true });

    this.props.onServicesFormSubmit(data).then(() => {
      this.setState({
        submitted: true,
        submitting: false,
      });
    });

    return false;
  }

  render() {
    const { location, cityConfig } = this.props;

    const isPortland = (cityConfig.city_name === "Portland");
    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div>
        <Meta
          title={`Food ${cityConfig.vehicle_type}s for Office Parks & Corporate Campuses | ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Schedule a daily or weekly rotation of food ${vehicleType}s to visit your employees or building tenants all at little to no cost to you!`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Office Services",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <Hero photo="https://static.seattlefoodtruck.com/photos/serviceshero.png">
          <h1>Food {cityConfig.vehicle_type}s @ Work</h1>
          <p>Schedule a daily or weekly rotation of food {vehicleType}s to visit you on site, set up and sell food directly to your employees or building tenants. All at little to not cost to your business.</p>
          <Scroll.Link
            smooth
            to="form"
            className="Button"
          >
            Start the conversation
          </Scroll.Link>
        </Hero>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>How it works</h4>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about1.jpeg"
                title="Curated Schedule"
                text={`Our proprietary platform curates and schedules ${cityConfig.city_name}’s best food ${vehicleType}s to visit your workplace on a daily rotation. By learning your employee’s favorite food ${vehicleType}s, your schedule changes as it goes, with cuisines from all over the globe.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about2.jpg"
                title="Fully Automated"
                text={`Your employees receive weekly menu emails of food ${vehicleType}s scheduled each week. A customized web page is also built for your company to view that week’s food ${vehicleType} lunch lineup. Take the guesswork out of lunch time planning.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about3.jpg"
                title="Hassle Free"
                text={`Food ${vehicleType}s arrive, serve and sell lunch to your employees for 3 hours and leave with no mess behind. Employees can order at the truck or skip the line and order online. All food is made to order from a gourmet kitchen on wheels.`}
              />
            </div>
          </div>
        </section>

        <section className="NewSection">
          <LogoList portland={isPortland} />
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>A New Mobile Corporate Dining Solution</h4>
            <div className={tripleStyles.Triple_wrapper + " " + tripleStyles.Triple_wrapper__two + " " + tripleStyles.Triple_wrapper__services}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about4.png"
                title="Retain and recruit top talent"
                text="Catered lunches are an employees favorite workplace perk. With better talent, you can increase your business, employee loyalty, and reduce costly turnover."
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about5.png"
                title="Community Spirit in the Workplace"
                text="Mealtimes are a natural target for building a sense of joint activity and community. Help maximize your teams feeling of togetherness and improve teamwork when it matters the most."
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about6.png"
                title="Boost Office Morale and Productivity"
                text="Food is a powerful and simple way to increase productivity, team cohesion, and motivation. Providing company-sponsored lunches demonstrates that you value their contribution to the success of your company. "
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about7.png"
                title="Support Local Businesses"
                text={`Our owner-operated food ${vehicleType}s favor quality, variety and sustainability. Experience cuisines from all over the world without ever leaving your office.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/about8.png" imgClass="img-about8"/>
              <div className={styles.Cta_copy}>
                <h4>Employee Subsidization</h4>

                <p>Reward individual teams or provide employees lunch daily free of charge. Our subsidization program allows you to set an amount per employee, while we handle the rest.</p>

                <div>
                  <button
                    className="Button"
                    disabled
                  >
                    Coming soon!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>Food {vehicleType} experience</h4>
            <div className="u-grid-row">
              <div className={styles.Experience_image + " u-grid-col u-grid-lg-twoThirds"}>
                <img src="/static/images/truck_illo.png" />
              </div>
              <div className="u-grid-col u-grid-lg-third">
                <table className={styles.Experience_table}>
                  <tbody>
                    <tr>
                      <td><h6>Site requirements</h6></td>
                    </tr>
                    <tr>
                      <td>Power: Not needed!</td>
                    </tr>
                    <tr>
                      <td>Space: 30ft x 8ft area</td>
                    </tr>
                    <tr>
                      <td>Water: Not needed!</td>
                    </tr>
                    <tr>
                      <td>Parking: Flat space is all that is needed</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          className="Button"
                          href=""
                        >
                          Learn about the day-to-day experience
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Scroll.Element name="form">
          <section className="PaddedSection--morePadding">
            <div className="Container">
              <h4>Let us help with your office needs</h4>
              {this.state.submitting && (
                <p>Sending your inquiry...</p>
              )}
              {this.state.submitted && (
                <p>Your inquiry has been sent. We'll be in touch soon!</p>
              )}
              {!this.state.submitting && !this.state.submitted && (
                <ServicesForm onSubmit={this.onFormSubmit} formType="offices"/>
              )}
            </div>
          </section>
        </Scroll.Element>
      </div>
    );
  }
}

OfficeParks.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  onServicesFormSubmit: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    },
    onServicesFormSubmit(data) {
      return dispatch(servicesActions.onServicesFormSubmit(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeParks);
